import { Avatar, Tag } from 'antd';
import React from 'react';
import { Client, DeviceAppVersion, UserConsent } from '../../stores/client';
import { TopTabs } from '../top-tabs';
import style from './style.module.scss';
import { ReactComponent as CloseIcon } from '../../icons/X.svg';
import moment from 'moment';
import { UserDetailsTab } from './user-details-tab';
import { ReactComponent as CheckIcon } from '../../icons/CheckCircle.svg';
import { ReactComponent as EmptyCheckIcon } from '../../icons/CheckCircleEmpty.svg';
import { coucheTardRed } from '../../utils/const';
import { UserCouponsTab } from './user-coupons-tab';
import { UserRewardsTab } from './user-rewards-tab';

type UserProfileProps = {
    onCloseClick: () => void;
    clientObject?: Client;
    resetPassword?: () => void;
    paymentMethod?: {
        loading: boolean;
        data: string | null;
    };
    deviceAppVersion?: {
        loading: boolean;
        data: DeviceAppVersion | null;
    };
    userConsents?: UserConsent[] | null;
    onUpdateConsentStatus?: (
        userConsentRecordId: number,
        status: string
    ) => void;
    isUpdatingConsentStatus?: boolean;
};

export const UserProfile = ({
    onCloseClick,
    clientObject,
    resetPassword,
    paymentMethod,
    deviceAppVersion,
    userConsents,
    onUpdateConsentStatus,
    isUpdatingConsentStatus,
}: UserProfileProps) => {
    return (
        <>
            <div className={style.container}>
                <div className={style.header}>
                    <div className={style.close} onClick={onCloseClick}>
                        <CloseIcon />
                    </div>
                    <div className={style.client_id}>
                        CLIENT #{clientObject?.id}{' '}
                        <span className={style.deleted_user}>
                            {clientObject?.deletedAt !== null && (
                                <Tag color="red" style={{ border: 'none' }}>
                                    This is a deleted user
                                </Tag>
                            )}
                        </span>
                    </div>
                    <div className={style.user_info}>
                        <Avatar
                            shape="circle"
                            style={{
                                verticalAlign: 'middle',
                                backgroundColor: `${coucheTardRed}`,
                            }}
                            size={64}
                            src={clientObject?.picture ?? ''}
                        >
                            {clientObject?.firstName
                                ?.charAt(0)
                                .toLocaleUpperCase()}
                            {clientObject?.lastName
                                ?.charAt(0)
                                .toLocaleUpperCase()}
                        </Avatar>
                        <div className={style.user_info_and_contact}>
                            <div className={style.user_title}>
                                {clientObject?.birthday
                                    ? `${clientObject?.firstName} ${
                                          clientObject?.lastName
                                      } (${moment().diff(
                                          clientObject?.birthday,
                                          'years'
                                      )})`
                                    : `${clientObject?.firstName} ${clientObject?.lastName}`}
                                {clientObject?.language && (
                                    <Tag
                                        color={
                                            clientObject?.language === 'fr'
                                                ? 'red'
                                                : 'orange'
                                        }
                                    >
                                        {clientObject?.language}
                                    </Tag>
                                )}
                            </div>
                            <div className={style.user_contact}>
                                <div className={style.phone_number}>
                                    {clientObject?.phoneNumber}
                                    {clientObject?.phoneNumber && (
                                        <div className={style.confirmed}>
                                            {clientObject?.isPhoneNumberConfirmed ? (
                                                <CheckIcon />
                                            ) : (
                                                <EmptyCheckIcon stroke="grey" />
                                            )}
                                        </div>
                                    )}
                                </div>
                                <div className={style.email}>
                                    {clientObject?.email}
                                    {clientObject?.email && (
                                        <div className={style.confirmed}>
                                            {clientObject?.isEmailConfirmed ? (
                                                <CheckIcon />
                                            ) : (
                                                <EmptyCheckIcon stroke="grey" />
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <TopTabs
                    tabsArray={[
                        {
                            name: 'Details',
                            key: 'user_details',
                            children: (
                                <UserDetailsTab
                                    clientObject={clientObject}
                                    resetPassword={resetPassword}
                                    paymentMethod={paymentMethod}
                                    deviceAppVersion={deviceAppVersion}
                                    userConsents={userConsents}
                                    onUpdateConsentStatus={
                                        onUpdateConsentStatus
                                    }
                                    isUpdatingConsentStatus={
                                        isUpdatingConsentStatus
                                    }
                                />
                            ),
                        },
                        {
                            name: 'Coupons',
                            key: 'user_coupons',
                            children: (
                                <>
                                    <UserCouponsTab
                                        clientObject={clientObject}
                                    />
                                </>
                            ),
                        },
                        {
                            name: 'Rewards',
                            key: 'user_rewards',
                            children: (
                                <>
                                    <UserRewardsTab
                                        clientObject={clientObject}
                                    />
                                </>
                            ),
                        },
                    ]}
                />
            </div>
        </>
    );
};
